<template>
  <div>
    <div class="produto-adicionar">
      <div class="conteudo-interno">
        <div class="nova-comunicacao-container">
          <h1>Adicionar Prêmio</h1>
          <formulario-cadastro-premios :editar="false" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const FormularioCadastroPremios = () => import("@/components/elementos/paginas/FormularioCadastroPremios");
export default {
  name: "CadastroPremiosAdicionar",
  components: {
    FormularioCadastroPremios,
  },
};
</script>
